/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				this.video();
				this.navbar();
				this.searchbox();
				this.publication();
				this.lightbox();
			},

			video: function() {
				// pauze and play button for the video screen
				$("#button-pauze").on("click", function () {
					var vid = document.getElementById("bgvid");
					vid.pause();
					$(this).hide();
					$('#button-play').show();
				});
				$("#button-play").on("click", function () {
					var vid = document.getElementById("bgvid");
					vid.play();
					$(this).hide();
					$('#button-pauze').show();
				});

				//Scroll animation when there is a video
				$("#button-down").on("click", function () {
					event.preventDefault();
					var $root = $('html, body');
					$root.animate({
						scrollTop: $($.attr(this, 'href')).offset().top - 56
					}, 500);
				});
			},

			navbar: function() {
                $("#close-mobile-menu").on("click", function () {
                    $('.navmenu').offcanvas('hide');
                });

				$(".dropdown-toggle").dropdown();
				if ($(".single-image-header").length > 0) {
					$('#nav_bar').addClass('navbar-fixed-top');
					$('#nav_bar').removeClass('container');
					$('#navbar_inner').addClass('container');
					$('#nav_bar').addClass('navbar-border');
				}
				else {
					$(window).scroll(function () {
						if ($(window).scrollTop() > 40) {
							$('#nav_bar').addClass('navbar-fixed-top');
							$('#nav_bar').removeClass('container');
							$('#navbar_inner').addClass('container');
							$('#nav_bar').addClass('navbar-border');

						}
						if ($(window).scrollTop() < 39) {
							$('#nav_bar').removeClass('navbar-fixed-top');
							$('#nav_bar').addClass('container');
							$('#navbar_inner').removeClass('container');
							$('#nav_bar').removeClass('navbar-border');
						}
					});
					// //Checks window when the screen is resized and makes the navbar fixed top.
					// $(window).resize(function () {
					// 	if ($(window).width() < 1200) {
					// 		$(window).scroll(function () {
					// 			if ($(window).scrollTop() > 40) {
					// 				$('#nav_bar').addClass('navbar-fixed-top');
					// 				$('#nav_bar').removeClass('container');
					// 				$('#navbar_inner').addClass('container');
					// 				$('#nav_bar').addClass('navbar-border');
					//
					// 			}
					// 			if ($(window).scrollTop() < 39) {
					// 				$('#nav_bar').removeClass('navbar-fixed-top');
					// 				$('#nav_bar').addClass('container');
					// 				$('#navbar_inner').removeClass('container');
					// 				$('#nav_bar').removeClass('navbar-border');
					// 			}
					// 		});
					// 	}
					// 	else {
					// 		$(window).scroll(function () {
					// 			if ($(window).scrollTop() > 75) {
					// 				$('#nav_bar').addClass('navbar-fixed-top');
					// 				$('#nav_bar').removeClass('container');
					// 				$('#navbar_inner').addClass('container');
					// 				$('#nav_bar').addClass('navbar-border');
					// 			}
					// 			if ($(window).scrollTop() < 74) {
					// 				$('#nav_bar').removeClass('navbar-fixed-top');
					// 				$('#nav_bar').addClass('container');
					// 				$('#navbar_inner').removeClass('container');
					// 				$('#nav_bar').removeClass('navbar-border');
					// 			}
					// 		});
					// 	}
					// });

					// //Check screen width when opening website and makes the navbar fixed top.
					// if ($(window).width() < 1200) {
					// 	$(window).scroll(function () {
					// 		if ($(window).scrollTop() > 40) {
					// 			$('#nav_bar').addClass('navbar-fixed-top');
					// 			$('#nav_bar').removeClass('container');
					// 			$('#navbar_inner').addClass('container');
					// 			$('#nav_bar').addClass('navbar-border');
					// 		}
					// 		if ($(window).scrollTop() < 39) {
					// 			$('#nav_bar').removeClass('navbar-fixed-top');
					// 			$('#nav_bar').addClass('container');
					// 			$('#navbar_inner').removeClass('container');
					// 			$('#nav_bar').removeClass('navbar-border');
					// 		}
					// 	});
					// }
					// else {
					// 	$(window).scroll(function () {
					// 		if ($(window).scrollTop() > 75) {
					// 			$('#nav_bar').addClass('navbar-fixed-top');
					// 			$('#nav_bar').removeClass('container');
					// 			$('#navbar_inner').addClass('container');
					// 			$('#nav_bar').addClass('navbar-border');
					// 		}
					// 		if ($(window).scrollTop() < 74) {
					// 			$('#nav_bar').removeClass('navbar-fixed-top');
					// 			$('#nav_bar').addClass('container');
					// 			$('#navbar_inner').removeClass('container');
					// 			$('#nav_bar').removeClass('navbar-border');
					// 		}
					// 	});
					// }
				}
			},

			searchbox: function() {
				// search box animation
				$("#search-icon").on("click", function () {
					if ($("#li-search-form").hasClass("active")) {
						if ($('#search-form').val() !== "") {
							$('#navbar-form').submit();
						}
						else {
							// $("#li-search-icon").css('paddingLeft','30px');
							$("#li-search-icon").animate({
								paddingLeft: "30px"
							}, 500);
							$("#search-form").animate({
								width: "0",
								padding: "0px"
							}, 500);
							setTimeout(function () {
								$("#li-search-form").removeClass("active");
								$("#li-search-form").delay("slow").hide();
							}, 500);
						}
					}
					else {
						$("#li-search-form").show();
						$("#li-search-icon").css('paddingLeft','0px');
						$("#search-form").animate({
							width: "180px",
							padding: "6px 12px"
						}, 500);
						$("#li-search-form").addClass("active");
					}

				});

				//Mobile search box animation
				$("#search-icon-navmenu").on("click", function () {
					if ($(".navmenu-form").hasClass("active")) {
						if ($('#search-form-navmenu').val() !== "") {
							$('#navmenu-form').submit();
						}
						else {
							$("#search-form-navmenu").animate({
								width: "0",
								padding: "0px"
							}, 500);
							setTimeout(function () {
								$(".navmenu-form").removeClass("active");
								$("#search-icon-navmenu").css("float", "left");
								$(".navmenu-form").delay("slow").hide();
							}, 500);
						}
					}
					else {
						$("#search-icon-navmenu").css("float", "right");
						$("#search-form-navmenu").animate({
							width: "110px",
							padding: "6px 12px"
						}, 500);
						$(".navmenu-form").show();
						setTimeout(function () {
							$(".navmenu-form").addClass("active");
						}, 500);
					}
				});
			},

			publication: function() {
				$("#select-publication").on("change", function () {
					window.location.href = $( "#select-publication" ).val();
					$("#select-publication").prop('disabled', true);
				});
			},

			lightbox: function() {

				$('.lightbox-image').click(function(){
					$('.modal-body').empty();
					var title = $(this).parent('a').attr("title");
					$('.modal-title').html(title);
					$($(this).parents('div').html()).appendTo('.modal-body');
					$('#myModal').modal({show:true});
					$('.modal-body *').addClass('lightbox-body');
					$('.last-image-overlay').addClass('remove-last-image-overlay');
				});

				$('#next-btn').click(function() {
					var link = $('.modal-body a');
					var number = parseInt(link.attr('title').match(/\S+$/));
					number++;
					var count = $("#picture-container a").length;
					if(number > count) {
						number = 1;
					}
					$('.modal-body').html($('#picture-container').find('a[title="Image ' + number + '"]').parent('div').html());
					$('.modal-title').text('Image ' + number);
					$('.modal-body *').addClass('lightbox-body');
				});
				$('#prev-btn').click(function() {
					var link = $('.modal-body a');
					var number = parseInt(link.attr('title').match(/\S+$/));
					number--;
					var count = $("#picture-container a").length;
					if(number === 0) {
						number = count;
					}
					$('.modal-body').html($('#picture-container').find('a[title="Image ' + number + '"]').parent('div').removeAttr('style').html());
					$('.modal-title').text('Image ' + number);
					$('.modal-body *').addClass('lightbox-body');
				});
			},
			
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.